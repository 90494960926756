const errorHandler = (error) => {
    var errorData = {
        type: "error",
        label: "Blad",
        param1: '',
        param2: '',
        param3: '',
    };
    switch (error.code) {
        case "request_limit_exceeded":
            errorData.message = "PrzekroczylesDopuszczalnyLimitZapytanSprobujPonownieZa1Minute";
            break;
        case "statement_not_checked":
            errorData.message = "ZaznaczPotwierdzenieOPoprawnosciDanychWDokumencie";
            break;
        case "unknow_document_type":
            errorData.message = "NieobslugiwanyTypDokumentu";
            break;
        case "cliet_has_waiting_document":
            errorData.message = "DokumentTegoTypuJuzOczekujeNaAkceptacje";
            break;
        case "document_exist":
            errorData.message = "DokumentTegoTypuZostalJuzZaakceptowanyINieMozeBycWypelnionyDrugiRaz";
            break;
        case "sign_name_mismatch":
            errorData.message = "OsobaZalogowanaNieJestWpisanaJakoReprezentant";
            break;
        case "sign_name_mismatch_board_member":
            errorData.message = "OsobaZalogowanaNieJestWpisanaJakoPelnomocnik;"
            break;
        case "pin_mismatch":
            errorData.message = "NiepoprawnyPIN";
            break;
        case "phone":
            errorData.message = "blednyFormatNrTelefonu";
            break;
        case "pin_mismatch_limit":
            errorData.message = "WprowadzonoZbytWieleRazyNiepoprawnyPINPonownePotwierdzeniePINEmBedzieMozliweZa24GodzinyWRazieDalszychProblemowProsimyOKontaktZSupportem";
            break;
        case "invalid_assignee_nip_id":
            errorData.message = "BlednyNIPCesjonariusza";
            break;
        case "withdrawals_locked":
            errorData.message = "WyplatyZostalyZablokowane";
            break;
        case "incorrect_account":
            errorData.message = "NiepoprawnyNumerKonta";
            break;
        case "bank_account":
            errorData.message = "BrakUstawionegoKontaDoWyplat";
            break;
        case "client_balance_too_low":
            errorData.message = "SaldoZbytNiskieAbyPokrycProwizjeOdWyplaty";
            break;
        case "wrong_amount_or_currency":
            errorData.message = "BlednaWalutaLubKwotaWyplaty";
            break;
        case "no_system":
            errorData.message = "NiepoprawnySystemWalut";
            break;
        case "no_user":
            errorData.message = "BrakDanychUzytkownika";
            break;
        case "amount_too_low":
            errorData.message = "ZbytMalaKwotaWyplaty";
            break;
        case "amount_too_high":
            errorData.message = "ZbytDuzaKwotaWyplaty";
            break;
        case "exist_waiting_payoff":
            errorData.message = "WDanejWalucieIstniejeOczekujacaWyplata";
            break;
        case "reject_error":
            errorData.message = "NieMoznaAnulowacWyplaty";
            break;
        case "soap_connection_error":
            errorData.message = "BladPolaczeniaSprobujPonownie";
            break;
        case "Forbidden":
            errorData.type = "warning";
            errorData.label = "Ostrzezenie";
            errorData.message = "BrakUprawnienDoWyswietleniaNiektorychElementow";
            break;
        case ((process.env.NODE_ENV || '').trim() !== 'production') && undefined:
            errorData.message = "Błąd JavaScript. Zgłosić do Web Developera";
            break;
        case "not_available_for_support":
            errorData.message = "AkcjaNiedostepnaDlaSupportu";
            break;
        case "withdrawals_unnecessary":
            errorData.message = "WyplataSrodkowNaSaldoJestMozliwaGdyMaOnoUjemnaWartoscLubZgromadzoneSrodkiNiePozwalajaNaOplacenieZadanOczekujacychNaAkceptacje";
            break;
        case "withdrawals_balance_and_jobs":
            errorData.message = "ProbujeszWyplacicNaSaldoWiecejNizWynosiSumaUjemnegoSaldaIZadanOczekujacychNaAkceptacjeMozeszWyplacicMaksymalnieXNaSaldoPozostalaKwoteMozeszWyplacicTylkoBezposrednioNaKontoBankowe";
            errorData.param1 = error.param1 ? error.param1 : '';
            break;
        case "withdrawals_only_invoices":
            errorData.message = "ProbujeszWyplacicNaSaldoWiecejNizWynosiSumaNieoplaconychFakturMozeszWyplacicMaksymalnieXZlNaSaldoCoWynikaZNieoplaconychFakturBRPozostalaKwoteMozeszWyplacicTylkoBezposrednioNaKontoBankowe";
            errorData.param1 = error.param1 ? error.param1 : '';
            break;
        case "withdrawals_unexpected_balance":
            errorData.message = "ProbujeszWyplacicNaSaldoWiecejNizWynosiSumaNieoplaconychFakturOrazPrzekroczeniaDozwolonegoZadluzeniaMozeszWyplacicMaksymalnieXZlNaSaldoCoWynikaZBRXZlNieoplaconychFakturBRXZlPrzekroczeniaDozwolonegoZadluzeniaBRPozostalaKwoteMozeszWyplacicTylkoBezposrednioN";
            errorData.param1 = error.param1 ? error.param1 : '';
            errorData.param2 = error.param2 ? error.param2 : '';
            errorData.param3 = error.param3 ? error.param3 : '';
            break;
        default:
            errorData.message = "WewnetrznyBladSerweraSprawdzSwojePolaczenieZInternetemIOdswiezStrone";
            break;
    }

    return errorData;
}

export default errorHandler;