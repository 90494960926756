import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      serverStatus: "",
      paymentsBalance: {},
      paymentsBalanceIAI: {},
      totalBalance: {},
      charges: [],
      ticketList: [],
      taskList: [],
      allInvoices: [],
      documents: [],
      paymentsSummary: {},
      verifiedByCodeTicket: 0,
    }
  },
  getters: {
    serverStatus(state) {
      return state.serverStatus;
    },
    paymentsBalance(state) {
      return state.paymentsBalance;
    },
    paymentsBalanceIAI(state) {
      return state.paymentsBalanceIAI;
    },
    clientType(state) {
      return state.clientType;
    },
    totalBalance(state) {
      return state.totalBalance;
    },
    billingBalance(state) {
      return state.billingBalance;
    },
    charges(state) {
      return state.charges;
    },
    ticketList(state) {
      return state.ticketList;
    },
    taskList(state) {
      return state.taskList;
    },
    allInvoices(state) {
      return state.allInvoices;
    },
    documents(state) {
      return state.documents;
    },
    paymentsSummary(state) {
      return state.paymentsSummary;
    },
    verifiedByCodeTicket(state) {
      return state.verifiedByCodeTicket;
    }
  },
  mutations: {
    SET_SERVER_STATUS(state, { status }) {
      state.serverStatus = status;
    },
    SET_TOTAL_BALANCE(state, { totalBalance }) {
      state.totalBalance = { ...totalBalance };
    },
    SET_BILLING_BALANCE(state, { billingBalance }) {
      state.billingBalance = { ...billingBalance };
    },
    SET_PAYMENTS_BALANCE(state, { paymentsBalance }) {
      state.paymentsBalance = { ...paymentsBalance };
    },
    SET_PAYMENTS_BALANCEIAI(state, { paymentsBalanceIAI }) {
      state.paymentsBalanceIAI = { ...paymentsBalanceIAI };
    },
    SET_CLIENT_TYPE(state, { clientType }) {
      state.clientType = { ...clientType };
    },
    SET_CHARGES(state, { charges }) {
      state.charges = { ...charges };
    },
    SET_TICKETS(state, { ticketList }) {
      state.ticketList = ticketList;
    },
    SET_TASKS(state, { taskList }) {
      state.taskList = { ...taskList };
    },
    SET_INVOICES(state, { invoices }) {
      state.allInvoices = [...invoices];
    },
    SET_DOCUMENTS(state, { documents }) {
      state.documents = [...documents];
    },
    SET_PAYMENTS_SUMMARY(state, { summary }) {
      state.paymentsSummary = { ...summary };
    },
    SET_VERIFIED_BY_CODE_TICKET(state, verifiedByCodeTicket) {
      state.verifiedByCodeTicket = verifiedByCodeTicket;
    },
  },
  actions: {
    fetchDashboardData(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get('/api/dashboard/get').then((res) => {
          const tempChargesObj = {};
          for (const income in res.data.balance_summary) {
            if (income !== "all") {
              for (const currency in res.data.balance_summary[income]) {
                if (res.data.balance_summary[income][currency] < 0) {
                  tempChargesObj[income] = tempChargesObj[income] || {};
                  tempChargesObj[income][currency] = res.data.balance_summary[income][currency];
                }
              }
            }
          }
          if (res.data.balance_summary) {
            context.commit("SET_PAYMENTS_SUMMARY", { summary: res.data.balance_summary.all });
          }
          if (tempChargesObj) {
            context.commit("SET_CHARGES", {
              charges: tempChargesObj,
            });
          }
          if (res.data.balance) {
            res.data.balance.balance = parseFloat(res.data.balance.balance);
            res.data.balance.debt_limit = parseFloat(res.data.balance.debt_limit);
            res.data.balance.unpaid_invoices_amount = parseFloat(res.data.balance.unpaid_invoices_amount);
            context.commit("SET_TOTAL_BALANCE", { totalBalance: { ...res.data.balance } });
          }
          if (res.data.payments) {
            context.commit("SET_PAYMENTS_BALANCE", { paymentsBalance: { ...res.data.payments.balance } });

            if (res.data.payments.balanceIAI) {
              context.commit("SET_PAYMENTS_BALANCEIAI", { paymentsBalanceIAI: { ...res.data.payments.balanceIAI } });
            }
          }

          context.commit("SET_BILLING_BALANCE", { billingBalance: res.data.billing_balance });

          if (res.data.client_type) {
            context.commit("SET_CLIENT_TYPE", { clientType: res.data.client_type });
          }

          const ticketsArray = [];
          for (const ticket in res.data.tickets) {
            ticketsArray.push(res.data.tickets[ticket])
          }
          if (ticketsArray.length) {
            context.commit('SET_TICKETS', { ticketList: ticketsArray });
          }
          if (res.data.unapproved_jobs) {
            if (typeof res.data.unapproved_jobs.value !== "string") {
              context.commit('SET_TASKS', { taskList: res.data.unapproved_jobs.value });
            }
          }

          if (res.data.unpaid_invoices.value?.length) {
            context.commit('SET_INVOICES', {
              invoices: [...res.data.unpaid_invoices.value]
            });
          } else {
            const invoicesArray = [];
            for (let id in res.data.unpaid_invoices.value) {
              invoicesArray.push(res.data.unpaid_invoices.value[id]);
            }
            context.commit('SET_INVOICES', {
              invoices: invoicesArray
            });
          }
          if (res.data.waiting_documents) {
            context.commit('SET_DOCUMENTS', { documents: res.data.waiting_documents.filter((doc) => doc.status === "waiting") });
          }

          if (res.data.system_status.status) {
            context.commit("SET_SERVER_STATUS", { status: res.data.system_status.status });
          }
          context.commit("SET_VERIFIED_BY_CODE_TICKET", res.data.verified_by_code_ticket);
          context.dispatch("toggleLoader", false, { root: true });
          resolve(res);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        });
      })
    },
    fetchTaskstoAccept(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get("/api/balance/getUnapprovedJobs").then((res) => {
          if (typeof res.data.value !== "string") {
            context.commit('SET_TASKS', { taskList: res.data.value });
          } else {
            context.commit('SET_TASKS', {});
          }
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    },
  }
}