export function formatDate(date) {
  const month = date.getMonth() + 1;
  const monthFormatted = month.toString().length === 1 ? "0" + month : month;

  const day = date.getDate();
  const dayFormatted = day.toString().length === 1 ? "0" + day : day;
  return `${date.getFullYear()}-${monthFormatted}-${dayFormatted}`;
}

export function formatDocumentType(type, clientType) {
  switch (type) {
    case "agreement":
    case "agreement_v2":
    case "agreement_confirmation_booking":
    case "agreement_confirmation_booking_no_kyc":
    case "agreement_confirmation":
    case "agreement_confirmation_no_kyc":
    case "agreement_confirmation_fixedterm":
    case "agreement_confirmation_fixedterm_no_kyc":
      if (clientType === 'shop') {
        return "UmowaZIdoSell";
      } else {
        return "UmowaZIdoBooking";
      }
    case "idopay_agreement":
    case "idopayment_agreement":
    case "agreement_confirmation_idopay":
      return "UmowaZIdoPayments";
    case "onboarding":
      return "AnkietaKYCAML";
    case "cession_v2":
    case "cession":
      return "Cesja";
    case "termination":
      return "Wypowiedzenie";
    case "saq":
      return "SAQ";
    default:
      return "";
  }
}

export function formatDocumentStatus(status) {
  switch (status) {
    case "rejected":
      return "odrzucony";
    case "accepted":
      return "zaakceptowany";
    case "waiting":
      return "oczekuje";
    default:
      return "";
  }
}

export function formatPaymentName(system) {
  switch (system) {
    case "blik":
      return "BlikIdoPay";
    case "cards":
      return "KartyPlatniczeIdoPay";
    case "paybylink":
      return "PayByLinkIdoPay";
    case "paypo":
      return "PayPoIdoPay";
    case "idosellbroker":
      return "IdoSellBrokerIdoPay";
    case "smile":
      return "SmilePaczkomatyInPostIdoPay";
    case "payoff":
      return "WyplataZaPobrania";
    case "payoff_IdoPayments":
      return "WyplataZaIdoPay";
    case "subscription":
      return "Subskrypcja";
    case "implementation_packages":
      return "PakietyWdrozeniowe";
    case "installation_fees":
      return "OplatyInstalacyjne";
    case "optional_services":
      return "UslugiOpcjonalne";
    case "licenses_for_applications":
      return "LicencjeISubskrypcjeNaAplikacjePomocnicze";
    case "individual_training":
      return "SzkoleniaIndywidualne";
    case "program_services":
      return "UslugiProgramistyczneNaPisanieIndywidualnychProgramow";
    case "payment_to_balance":
      return "ZasilenieSalda";
    case "iai_pay":
      return "PlatnosciIdoPay";
    case "courier_shipments":
      return "PrzesylkiKurierskieIdoSellBroker";
    case "mails_sms":
      return "PlatneWiadomosciEmailLubSms";
    case "shopping_ads":
      return "ReklamyProduktoweIRemarketingIAIAds";
    case "refactoring":
      return "RefakturaOplatInnychUslugodawcow";
    case "donation_idopayments":
      return "ProwizjaZaWplaty";
    case "request_idopayments":
      return "ProwizjaZaWyplaty";
    case "chargeback":
      return "ReklamacjeWplatIdoPay";
    case "balance":
      return "WyplatyNaPoczetWydatkowNaUslugiIAI";
    case "refund":
      return "ZwrotyPlatnosciIdoPay";
    case "commission_donation":
      return "ProwizjeZaPlatnosci";
    case "commission_request":
      return "ProwizjeZaWyplaty";
    case "commission_request_IdoPayments":
      return "ProwizjeZaWyplatyIdoPay";
    case "shoprenter_go":
      return "ShoprenterGo";
    case "checkout":
      return "Checkout 2.0";
    default:
      return "Podsumowanie";
  }
}

export function formatTaskPricingType(pricing_type) {
  switch (pricing_type) {
    case "contract":
      return "ZakontraktowanaKwota";
    case "realtime":
      return "WedlugCzasuRzeczywistego";
    case "fault":
      return "UsterkaZNaszejWiny";
    case "development":
      return "PraceRozwojowe";
    case "prepaid":
      return "Przedplata";
    case "gift":
      return "Prezent";
    case "project_contract":
      return "PraceWRamachProjektu";
    default:
      return "";
  }
}

export function formatServiceStatus(status) {
  let statusFormatted = {
    literal: "",
    class: "",
  };
  switch (status) {
    case "OK":
      statusFormatted.literal =
        "WszystkieUslugiNaSerwerzeDzialajaWTejChwiliPoprawnie";
      statusFormatted.class = "--color_success";
      break;
    case "Warning":
      statusFormatted.literal =
        "WTejChwiliMogaWystepowacProblemyZDzialaniemUslugNaSerwerze";
      statusFormatted.class = "--color_error";
      break;
    case "Acknowledged":
      statusFormatted.literal =
        "ObecnieNaSerwerzePrzeprowadzanaJestInterwencjaTechniczna";
      statusFormatted.class = "--color_warning";
      break;
    case "Downtime":
      statusFormatted.literal =
        "WszystkieUslugiNaSerwerzeDzialajaWTejChwiliPoprawnie";
      statusFormatted.class = "--color_info";
      break;
    case "CRITICAL":
      statusFormatted.literal =
        "WTejChwiliMogaWystepowacProblemyZDzialaniemUslugNaSerwerze";
      statusFormatted.class = "--color_error";
      break;
    default:
      statusFormatted.literal = "WystapilBladOdswiezStrone";
      statusFormatted.class = "--color_error";
  }
  return statusFormatted;
}

/**
 * Sprawdza czy uzytkownik posiada uprawnienie umozliwiajace dostep do modulu
 *
 * @param requiredPerms - tablica z uprawnieniami umozliwiajacymi dostep
 * @param userPerms     - uprawnienia uzytkownika
 * @returns {boolean}
 */
export function hasPermissions(requiredPerms, userPerms) {
  if (requiredPerms.length) {
    for (let index = 0; index < requiredPerms.length; ++index) {
      if (userPerms[requiredPerms[index]]) {
        return true;
      }
    }
    return false;
  } else {
    return true;
  }
}