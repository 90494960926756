import axios from "@/axios"

export default {
  namespaced: true,
  state() {
    return {
      details: [],
      pages: 1,
    }
  },
  getters: {
    details(state) {
      return state.details
    },
    pages(state) {
      return state.pages
    }
  },
  mutations: {
    SET_DETAILS(state, payload) {
      state.details = [...payload]
    },
    SET_PAGES(state, { pages }) {
      state.pages = pages
    }
  },
  actions: {
    fetchDetails(context, { dateStart, dateEnd, currency, group, page }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/balance/getBalanceHistory/from/${dateStart}/to/${dateEnd}/currency/${currency}/group/${group}/page/${page}/limit/20`
          // , {
          //   from: dateStart,
          //   to: dateEnd,
          //   currency,
          //   group,
          //   page,
          //   limit: 20,
          // }
        ).then((res) => {
          const tempArr = [];
          for (const data in res.data.data) {
            tempArr.push(res.data.data[data]);
          }
          context.commit("SET_DETAILS", tempArr);
          context.commit("SET_PAGES", { pages: Math.ceil(parseInt(res.data.allRecords) / 20) });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })

    }
  }
}