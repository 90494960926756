<template>
  <div class="row">
    <div class="col-12"><Heading /></div>
    <div v-if="$mode !== 'shoprenter'" class="col-12 mb-5 d-flex justify-content-sm-end d-md-none">
      <a :href="$panelUrl">{{ $t("PANELAdministracyjny") }}</a>
    </div>
    <div class="col-12" v-if="verifiedByCodeTicket > 0">
      <Alert label="Uwaga" type="warning">
        {{$t("WZwiazkuZPrzekroczeniemPrzezTwojaFirmePulapuXWplywowNaSaldoWplatWyslalismyDoCiebieListWeryfikacyjny")}}
        {{$t("WiecejInformacjiZnajdzieszWKomunikacie")}}
        <router-link :to="`/support/ticket/${verifiedByCodeTicket}`">#{{verifiedByCodeTicket}}</router-link>
      </Alert>
    </div>
    <div class="col-12 section-margin" v-if="serverStatus !== ''">
      <SystemStatus :status="serverStatus" className="pt-4" />
    </div>
    <div class="col-12 section-margin" v-if="Object.keys(totalBalance).length">
      <SummaryHeading
        literal="PodsumowanieWydatkow"
        :value="
          $formatPrice(totalBalance.balance, totalBalance.billing_currency)
        "
        className="--color_warning"
        link="/balance/history"
        linkValue="PelnePodsumowanie"
        :bordered="true"
      >
        <div>
          <div
            class="mt-5"
            v-for="(chartCurrencyData, chartCurrency) in chargesChartObj"
            :key="chartCurrency"
          >
            <strong class="d-block mb-2">{{ chartCurrency }}</strong>
            <LineChart
              :currency="chartCurrency"
              :dataArray="chartCurrencyData.chartArrayData"
            />
          </div>
        </div>
      </SummaryHeading>
      <Alerts :total-balance="totalBalance" :billingBalance="billingBalance"></Alerts>
    </div>
    <div
      class="col-12 section-margin"
      v-if="Object.keys(paymentsBalance).length"
    >
      <SummaryHeading v-if="clientType.client !== 'shoprenter'"
        literal="ZgromadzoneSrodkiZWplat"
        :values="balancePaymentsFormatted"
        :multiLinks="withdrawalLinks"
        :bordered="true"
      />
      <SummaryHeading v-else
        literal="ZgromadzoneSrodkiZPobran"
        :values="balancePaymentsFormatted"
        link="/shoprentergo/withdraw"
        linkValue="WyplataSrodkow"
        :bordered="true"
      />
      <Alert label="Uwaga" type="error" v-if="$mode === 'booking' && Object.keys(paymentsBalanceIAI).length">
        <span>{{
            $t('NaObecnymSaldzieWplatZaPobraniaDo1032023SaldoZbieraloWplatyZIdoPayWIAIZnajdujaSieSrodkiGotoweDoWyplatyX').format(getCurrencyAlertString) +
            " " +
            $t('AbyJeWyplacicSkontaktujSieZDzialemWsparciaIdoPay')
          }}</span>
      </Alert>
    </div>
    <div class="col-12 section-margin" v-if="ticketList.length">
      <SummaryHeading
        literal="OstatnieKomunikaty"
        link="/support?tab=all"
        linkValue="WszystkieKomunikaty"
        :bordered="true"
      >
        <div class="ticket_item_list mt-4">
          <TicketListItem
            v-for="ticket in ticketList"
            :key="ticket.id"
            :type="ticket.type"
            :title="ticket.title"
            :id="ticket.id"
            :answ="ticket.answ"
            :creator="ticket.creator"
            :first_date="ticket.first_date"
            :last_creator="ticket.last_creator"
            :last_date="ticket.last_date"
            :jobs="ticket.jobs"
            :close_date="ticket.close_date"
            :waitingForAccept="ticket.jobs_need_approval"
          />
        </div>
      </SummaryHeading>
    </div>
    <div class="col-12 section-margin" v-if="Object.keys(taskList).length">
      <SummaryHeading literal="ZadaniaDoZatwierdzenia">
        <div class="task_item_list mt-4">
          <TaskListItem
            v-for="task in taskList"
            :key="task.id"
            :name="task.name"
            :person_assigned="task.assigned"
            :used_time="task.used_time"
            :est_time="task.est_time"
            :id="task.id"
            :ordertime="task.ordertime"
            :finishtime="task.finishtime"
            :price="task.price"
            :starttime="task.starttime"
            :status="task.status"
            :pricing_type="task.pricing_type"
            :approved_cost="task.approved_cost"
            :ticket="task.ticket"
            :progress="task.progress"
            :amount="task.amount"
            :currency="task.billing_currency"
            :balance_info="task.balance_info"
            @task-list-update="refreshTaskList"
          />
        </div>
      </SummaryHeading>
    </div>
    <div class="col-12 section-margin" v-if="allInvoices.length">
      <SummaryHeading literal="FakturyWymagajaceOplacenia" />
      <div class="table">
        <div class="table__content">
          <div class="table__row --header">
            <div
              v-for="(header, index) in tableInvoicesHeader"
              :key="header.id ? header.id : index"
              class="table__cell p-4 --header"
            >
              {{ $t(header) }}
            </div>
          </div>
          <InvoicesRow
            v-for="(invoice, key) in allInvoices"
            :key="invoice.invoice_id"
            :invoice_id="invoice.invoice_id"
            :number="invoice.number"
            :duedate="invoice.duedate"
            :setdate="invoice.setdate"
            :gros_amount="invoice.gros_amount"
            :billing_currency="invoice.billing_currency"
            :overdue="invoice.overdue"
            :approvaltime="invoice.approvaltime"
            :pf="invoice.number.indexOf('PF') !== -1"
            @setApprovaltime="setApprovaltime(key)"
          />
        </div>
      </div>
    </div>
    <div class="col-12 section-margin" v-if="documents.length">
      <SummaryHeading literal="DokumentyDoZatwierdzenia" />
      <div class="documents__table table">
        <table class="table__content">
          <tr class="table__row --header">
            <td class="table__cell --header px-2 py-3">
              {{ $t("TypDokumentu") }}
            </td>
            <td class="table__cell --header px-2 py-3">
              {{ $t("DokumentDodal") }}
            </td>
            <td class="table__cell --header --center px-2 py-3">
              {{ $t("DataPodpisaniaDokumentu") }}
            </td>
            <td class="table__cell --header --center px-2 py-3">
              {{ $t("Status") }}
            </td>
            <td class="table__cell --header --center px-2 py-3">
              {{ $t("Szczegoly") }}
            </td>
          </tr>
          <AccountDocumentsTableRow
            v-for="document in documents"
            :key="document.id"
            :type="document.type"
            :addedBy="document.addedBy"
            :additionDate="document.additionDate"
            :status="document.status"
            :id="document.id"
          />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SystemStatus from "@/components/SystemStatus.vue";
import SummaryHeading from "@/components/SummaryHeading.vue";
import LineChart from "@/components/LineChart.vue";
import TicketListItem from "@/components/TicketListItem.vue";
import TaskListItem from "@/components/TaskListItem.vue";
import Heading from "@/components/base/Heading.vue";
import InvoicesRow from "@/components/InvoicesRow.vue";
import AccountDocumentsTableRow from "@/components/AccountDocumentsTableRow.vue";
import { mapActions, mapGetters } from "vuex";
import { formatPaymentName } from "@/utils/utils";
import Alerts from "@/views/balance/Alerts";
import Alert from "@/components/base/Alert";

export default {
  components: {
    Alert,
    Alerts,
    SystemStatus,
    SummaryHeading,
    LineChart,
    TicketListItem,
    TaskListItem,
    Heading,
    InvoicesRow,
    AccountDocumentsTableRow,
  },
  data() {
    return {
      tableInvoicesHeader: [
        "NrDokumentu",
        "TerminPlatnosci",
        "DataWystawienia",
        "KwotaBrutto",
        "Szczegoly",
        "PobierzPlikDoWydruku",
      ],
    };
  },
  computed: {
    ...mapGetters({
      serverStatus: "dashboard/serverStatus",
      paymentsBalance: "dashboard/paymentsBalance",
      paymentsBalanceIAI: "dashboard/paymentsBalanceIAI",
      paymentsSummary: "dashboard/paymentsSummary",
      clientType: "dashboard/clientType",
      totalBalance: "dashboard/totalBalance",
      billingBalance: "dashboard/billingBalance",
      charges: "dashboard/charges",
      ticketList: "dashboard/ticketList",
      taskList: "dashboard/taskList",
      allInvoices: "dashboard/allInvoices",
      documents: "dashboard/documents",
      verifiedByCodeTicket: "dashboard/verifiedByCodeTicket",
    }),
    chargesChartObj() {
      const chartObj = {};
      for (const currency in this.paymentsSummary) {
        const dataArray = [];
        for (const charge in this.charges) {
          if (this.charges[charge][currency]) {
            const item = {
              name: this.labelLiteral(charge),
              value: this.charges[charge][currency],
            };
            dataArray.push(item);
          }
        }
        chartObj[currency] = {
          currency,
          chartArrayData: dataArray,
        };
      }
      return chartObj;
    },
    withdrawalLinks() {
      const multiLinks = {};
      multiLinks['WyplataSrodkowIdoPay'] = "/payments/withdrawal/idopay";
      if (this.clientType.client !== 'emporium') {
        multiLinks['WyplataSrodkowPobrania'] = "/payments/withdrawal/iai";
      }
      return multiLinks;
    },
    balancePaymentsFormatted() {
      const temp_arr = [];
      for (let curr in this.paymentsBalance) {
        temp_arr.push(this.$formatPrice(this.paymentsBalance[curr], curr));
      }
      return temp_arr;
    },
  },
  methods: {
    ...mapActions({
      fetchDashboardData: "dashboard/fetchDashboardData",
      fetchTasksToAccept: "dashboard/fetchTaskstoAccept",
      toggleLoader: "toggleLoader",
    }),
    labelLiteral(system) {
      return this.$t(formatPaymentName(system));
    },
    refreshTaskList() {
      const that = this;
      this.toggleLoader(true);
      this.fetchTasksToAccept().then(() => {
        that.toggleLoader(false);
      });
    },
    setApprovaltime(key) {
      this.allInvoices[key].approvaltime = 1;
    },
    getCurrencyAlertString() {
      return Object.keys(this.paymentsBalanceIAI).map(key => `${key}: ${this.paymentsBalanceIAI[key]}`).join(', ');
    }
  },
  created() {
    this.fetchDashboardData();
  },
  mounted() {
    document.title = this.$t("BiuroObslugiKlienta") + ' - panel ' + this.$user.client_id;
  },
};
</script>